<template>
    <div class="bg-primary py-5 py-md-5">
        <div class="container">
            <div class="d-flex flex-wrap justify-content-center align-items-center text-white">
                <div class="border border-white border-lg rounded-circle p-4 m-4" style="border-width: 18px !important">
                    <img src="../assets/img/line-chart.png" alt="" style="width: 8rem" />
                </div>

                <div class="m-4 text-white">
                    <h2 class="title text-left text-white mb-4--5">Notre plus-value</h2>
                    <div class="d-flex align-items-baseline mt-3 fs5" v-for="i in services.length" :key="i">
                        <span class="mr-3">{{ i }}.</span>
                        <span>{{ services[i - 1] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            services: ["Un bilan assurance et patrimonial", "Une offre différentielle", "Un accompagnement de vos projets"],
        };
    },
};
</script>
