<template>
    <div id="app" :class="{ shrink: isShrink }">
        <!-- <div class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge badge-primary d-block d-sm-none">xs</span>
            <span class="badge badge-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge badge-success d-none d-md-block d-lg-none">md</span>
            <span class="badge badge-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge badge-info d-none d-xl-block">xl</span>
        </div> -->
        <template>
            <b-navbar toggleable="lg" id="navbar" class="border-bottom">
                <b-navbar-toggle target="navbar-toggle-collapse" id="nav_toggle"></b-navbar-toggle>

                <b-navbar-brand>
                    <img src="./assets/img/ecds-logo.png" alt="" @click="scrollToElement('home')" />
                </b-navbar-brand>

                <b-collapse id="navbar-toggle-collapse" is-nav>
                    <b-navbar-nav class="ml-auto align-items-center">
                        <b-nav-item @click="scrollToElement('equipe')" to="/#">Qui sommes-nous ?</b-nav-item>
                        <b-nav-item @click="scrollToElement('services')" to="/#">Nos interventions</b-nav-item>
                        <b-nav-item @click="scrollToElement('plusvalue')" to="/#">Notre Plus-Value</b-nav-item>
                        <b-nav-item @click="scrollToElement('services')" to="/#">Nos partenaires</b-nav-item>
                        <b-nav-item to="/reglementation-rgpd">Règlementation - RGPD</b-nav-item>
                        <b-button variant="primary" to="/#" class="my-2 mx-3 d-flex align-items-center" @click="scrollToElement('contact')"
                            >Contact</b-button
                        >
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
            <router-view ref="view" class="flex-fill"></router-view>

            <footer class="fs-sm px-5">
                <div class="row no-gutters mx-0 my-3">
                    <div class="col">Créé par <a class="underline" href="https://mod1.fr">MOD1 CONSULTING</a> ©{{ new Date().getFullYear() }}</div>
                </div>
            </footer>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShrink: null,
        };
    },
    methods: {
        scrollToElement(target) {
            let element = this.$refs.view.$refs[target];
            const y = element.$el.getBoundingClientRect().top + window.pageYOffset - (this.isShrink ? 71 : 150 + 71);
            window.scrollTo({ top: y, behavior: "smooth" });
        },
    },
    mounted() {
        const refreshIsShrink = () => {
            this.$data.isShrink = document.documentElement.scrollTop >= 80;
        };

        refreshIsShrink();

        this.$nextTick(function () {
            window.addEventListener("scroll", refreshIsShrink);
        });
    },
};
</script>
