<template>
    <div class="content d-flex flex-column">
        <Accueil ref="home" />
        <Equipe ref="equipe" />
        <Services ref="services" />
        <PlusValue ref="plusvalue" />
        <Partenaires ref="partenaires" />
        <Contact ref="contact" />

        <!-- <div style="height: 1000px"></div> -->
    </div>
</template>

<script>
import Accueil from "./_Accueil.vue";
import Equipe from "./_Equipe.vue";
import Services from "./_Services.vue";
import PlusValue from "./_PlusValue.vue";
import Partenaires from "./_Partenaires.vue";
import Contact from "./_Contact.vue";

export default {
    components: {
        Accueil,
        Equipe,
        Services,
        PlusValue,
        Partenaires,
        Contact,
    },
};
</script>
