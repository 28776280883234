<template>
    <div>
        <div class="alert alert-success text-white d-flex align-items-center rounded-0 mb-0" role="alert" v-if="isFromTY">
            <i class="fa-solid fa-check mr-3"></i>
            <div>Merci de nous avoir contacté, nous allons traiter votre demande dans les plus brefs délais !</div>
        </div>

        <div class="accueil position-relative w-100 d-flex align-items-center justify-content-center">
            <img src="../assets/img/accueil.jpg" class="moving-bg" alt="" />
            <h1 class="home-title">
                <span class="text-primary">ECDS</span>, votre partenaire <br />en <span class="text-primary">ASSURANCES</span> et
                <span class="text-primary">PLACEMENTS</span> <br />sur le <span class="text-primary">GRAND EST</span>
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        return { isFromTY: _isFromTY };
    },
};
</script>
