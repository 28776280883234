<template>
    <div class="services bg-gray-2 py-5">
        <div class="container">
            <h2 class="title">Nous intervenons sur</h2>

            <div class="d-flex justify-content-center">
                <div>
                    <div class="d-flex align-items-baseline mt-3" v-for="service in services" v-bind:key="service">
                        <i class="fa-solid fa-check text-primary mr-3"></i>
                        <span>{{ service }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            services: [
                "L'assurance des professionnels agricoles, des artisans commerçants, des professions libérales",
                "Responsabilité civile (RC) Professionnelle / Décennale / Dirigeant",
                "Dommages ouvrages",
                "L'assurance des auto-entrepreneurs",
                "L'assurance des particuliers",
                "La gestion de patrimoine",
                "La défiscalisation",
                "La prévoyance et la retraite",
            ],
        };
    },
};
</script>
