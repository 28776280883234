/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "@/components/Home";
import _404 from "@/components/_404";
import ReglementationRGPD from "../components/ReglementationRGPD";

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        },
        {
            path: "/reglementation-rgpd",
            name: "reglementation-rgpd",
            component: ReglementationRGPD,
        },
        {
            path: "/thank-you",
            redirect: (to) => {
                localStorage.fromty = true;
                return "/";
            },
        },
        {
            path: "/404-not-found",
            name: "404",
            component: _404,
        },
        {
            path: "*",
            redirect: { name: "404" },
        },
    ],
});

export default router;
