<template>
    <div class="contact">
        <div class="container-md shadow my-0 my-lg-5 px-0">
            <div class="row no-gutters">
                <div class="col-12 col-lg-5 py-4 py-sm-5 bg-primary">
                    <h2 class="title text-uppercase text-white">Nous contacter</h2>

                    <div class="d-flex flex-wrap flex-lg-column justify-content-around align-items-center">
                        <div class="d-flex-center flex-column text-bold text-white my-4 my-md-5 my-xl-6 mx-3">
                            <h3>DIJON</h3>
                            <a class="text-white" href="tel:0609491925">06 09 49 19 25</a>
                            <a class="text-white" href="mailto:regis.petitboulanger@ecds-courtage.fr">regis.petitboulanger@ecds-courtage.fr</a>
                        </div>

                        <div class="d-flex-center flex-column text-bold text-white my-4 my-md-5 mx-3">
                            <h3>STRASBOURG</h3>
                            <a class="text-white" href="tel:0623344803">06 23 34 48 03</a>
                            <a class="text-white" href="mailto:melanie.demoulin@ecds-courtage.fr">melanie.demoulin@ecds-courtage.fr</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex flex-column px-2 py-4 px-sm-5 py-sm-5">
                    <h2 class="title text-uppercase mb-4--5 text-center">Demande de devis</h2>

                    <form action="https://formsubmit.co/regis.petitboulanger+contact@ecds-courtage.fr" method="POST" class="needs-validation" novalidate>
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_next" value="https://ecds-courtage.fr/thank-you" />
                        <input type="hidden" name="_subject" value="Nouvelle demande de contact" />
                        <input type="hidden" name="_template" value="table" />
                        <input type="text" name="_honey" style="display: none" />

                        <div class="row">
                            <div class="col-12">
                                <div class="form-label-group">
                                    <input type="text" class="form-control" id="name" name="Nom/Entreprise" placeholder="Alain Dupond" required />
                                    <label for="name">Nom / Entreprise*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-label-group">
                                    <input type="email" class="form-control" id="emailAdress" name="Email" placeholder="nom@exemple.fr" required />
                                    <label for="emailAdress">Adresse email*</label>
                                    <div class="invalid-feedback">Adresse email invalide</div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-label-group">
                                    <input type="tel" class="form-control" id="phoneNumber" name="Téléphone" placeholder="0605040302" />
                                    <label for="phoneNumber">Téléphone</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-label-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="object"
                                        name="Objet"
                                        placeholder="ex: Privatisation restaurant"
                                        required
                                    />
                                    <label for="object">Objet*</label>
                                    <div class="invalid-feedback">Veuillez renseigner l'objet de votre demande</div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-label-group">
                                    <textarea
                                        class="form-control"
                                        placeholder="Laissez votre message ici"
                                        id="message"
                                        name="Message"
                                        style="height: 150px"
                                        required
                                    ></textarea>
                                    <label for="message">Message*</label>
                                    <div class="invalid-feedback">Veuillez saisir un message pour votre demande</div>
                                </div>
                            </div>
                        </div>

                        <button class="w-100 btn btn-primary btn-lg mt-3" type="submit">Envoyer</button>
                    </form>
                    <div class="small mt-3 text-primary-dark">* champs obligatoire</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const form = document.querySelector(".needs-validation");

        // Loop over them and prevent submission
        form.addEventListener(
            "submit",
            (event) => {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.classList.add("was-validated");
            },
            false
        );
    },
};
</script>
