import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import { firestorePlugin } from "vuefire";
import AsyncComputed from "vue-async-computed";
import VueGtag from "vue-gtag";

require(`./assets/styles/site.scss`);

Vue.use(AsyncComputed);
Vue.use(firestorePlugin);
Vue.use(VueGtag, {
    config: { id: "G-HC86H3Q85W" },
});
Vue.config.productionTip = false;

import router from "@/services/router";
import App from "@/App";

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
